<style lang="scss">
.startseite {
  .top-intro-startseite {
    margin-bottom: 50px;
    margin-top: -10px;
    position: relative;
    @media (max-width: $width-md) {
      margin-top: 0;
    }
    .slogan {
      position: absolute;
      z-index: 2;
      width: 94%;
      left: 3%;
      text-align: center;
      color: #fff;
      top: 50%;
      margin-top: -70px;
      @media (max-width: 1280px) {
        margin-top: -55px;
      }
      @media (max-width: 768px) {
        margin-top: -45px;
      }
      @media (max-width: 500px) {
        top: 30px;
        margin-top: 0;
      }
      strong {
        font-size: 280%;
        @media (max-width: 1280px) {
          font-size: 220%;
        }
        @media (max-width: 768px) {
          font-size: 150%;
        }
      }
      span {
        display: block;
        font-size: 150%;
        margin-top: 15px;
        @media (max-width: 1280px) {
          font-size: 120%;
        }
        @media (max-width: 500px) {
          margin-top: 0;
        }
      }
    }
    .img-box {
      width: 100%;
      height: 400px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-bottom: 5px;
      position: relative;
      @media (max-width: 1280px) {
        height: 300px;
      }
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba($color: #000000, $alpha: 0.3);
      }
    }
  }
  h1 {
    font-weight: 500;
  }
  .subline {
    margin-bottom: 50px;
    text-transform: uppercase;
    font-size: 130%;
  }
  .headline-whitespace {
    //border:1px solid #eee;
    margin: 80px 0 50px 0;
    padding: 25px 15px;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 200px;
      height: 3px;
      margin: 20px auto;
      background: #eee;
    }
    @media (max-width: $width-sm) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    /*&:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-top: 3px solid $red;
      border-left: 3px solid $red;
      position: absolute;
      left: 0px;
      top: 0;
    }
    &:after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-bottom: 3px solid $red;
      border-right: 3px solid $red;
      position: absolute;
      right: 0px;
      bottom: 0;
    }*/
  }
  h2 {
    text-align: center;
    margin: 0;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 230%;
    letter-spacing: 2px;
    @media (max-width: 768px) {
      font-size: 150%;
    }
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  .intro-box {
    border: 0px solid red;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
    @media (max-width: 992px) {
      margin-bottom: 20px;
    }
    h3 {
      font-size: 150%;
      font-weight: 300;
      text-align: center;
      max-width: 50%;
      margin: 0 auto;
      @media (max-width: 1780px) {
        max-width: 70%;
      }
      @media (max-width: 1310px) {
        max-width: 80%;
      }

      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 30px;
        margin: 0px auto 10px auto;
        background: $red;
        display: none;
      }
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 30px;
        margin: 19px auto 0 auto;
        background: $red;
        display: none;
      }
      @media (max-width: 992px) {
        max-width: 100%;
        padding: 0 20px;
        font-size: 120%;
      }
      @media (max-width: 768px) {
        margin-top: 20px;
      }
    }
    .img-box {
      border: 0px solid blue;
      width: 100%;

      img {
        display: block;
        margin: 0 auto;
      }
    }
  }
  .keyword-box {
    margin-top: 40px;
    h4 {
      text-align: left;
      &:before {
        content: '';
        display: block;
        width: 100px;
        height: 3px;
        margin: 10px 0;
        background: $blue-darker;
      }
      &:after {
        content: '';
        display: block;
        width: 50px;
        height: 3px;
        margin: 10px 0;
        background: $blue;
      }
    }
    p {
      padding: 15px;
      @media (max-width: 992px) {
        padding: 15px 0;
      }
    }
  }

  .fact-sheet-btn {
    text-align: center;
    margin-top: 30px;
    @media (max-width: 1200px) {
      margin-top: 40px;
    }
    a {
      display: inline-block;
      position: relative;
      max-width: 90%;
      margin: 0 auto;
      font-size: 120%;
      @media (max-width: $width-sm) {
        max-width: 100%;
        font-size: 100%;
      }
      .frontBg {
        display: block;
        padding: 20px 35px;
        background: $blue-darker;
        color: #fff;
        position: relative;
        z-index: 3;
        @media (max-width: $width-sm) {
          padding: 20px 20px;
        }
      }
      .backBg {
        display: block;
        width: 100%;
        height: 100%;
        background: $blue;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 1;
      }
    }
    &:hover {
      .frontBg {
        background: $blue;
      }
      .backBg {
        background: $blue-darker;
      }
    }
  }
  .test-btn {
    display: none;
    border: 1px solid red;
    color: #ecf0f1;
    background: #e74c3c;
    width: 80px;
    height: 80px;
    border: 0;
    font-size: 1.5em;
    position: relative;
    span {
      position: absolute;
      transition: 0.3s;
      background: white;
      border-radius: 3px;
      &:first-of-type {
        top: 25%;
        bottom: 25%;
        width: 10%;
        left: 45%;
      }
      &:last-of-type {
        left: 25%;
        right: 25%;
        height: 10%;
        top: 45%;
      }
    }
    &:hover {
      span:first-of-type,
      span:last-of-type {
        transform: rotate(90deg);
      }
      span:last-of-type {
        left: 50%;
        right: 50%;
      }
    }
  }
  .border-bg-img {
    position: relative;
    margin: 30px;
    img {
      position: relative;
      z-index: 2;
    }
    &:before {
      content: '';
      display: block;
      border: 5px solid $blue;
      position: absolute;
      left: -25px;
      bottom: -25px;
      width: 100%;
      height: 100%;
    }
    &:after {
      content: '';
      display: block;
      border: 5px solid $blue-darker;
      position: absolute;
      left: 25px;
      top: -25px;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
  }
}
</style>

<template>
  <div class="startseite">
    <div class="top-intro-startseite shadow">
      <div class="slogan"><strong>Wir verbinden Menschen mit Marken</strong><span>Auf allen Kanälen. Zu jeder Zeit.</span></div>
      <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/top-header-v1.jpg')})` }"></div>
      <div class="img-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/startseite/top-header-v2.jpg')})` }"></div>
    </div>
    <div class="container">
      <!--<h1>Wir verbinden Menschen mit Marken.</h1>
      <div class="subline">Digital und analog</div>-->
      <button class="test-btn"><span></span><span></span></button>
      <div class="headline-whitespace">
        <h2>PROFITIEREN SIE VON UNSEREN STÄRKEN</h2>
      </div>
      <div class="row keyword-box">
        <div class="col-lg-6">
          <h4>VERTRAUEN</h4>
          <p>
            Seit über 116 Jahren ist die Kleine Zeitung ein verlässlicher Partner in der Medienlandschaft. Die ausgezeichnete journalistische Qualität unserer Inhalte (print und digital), die starke Verbundenheit unserer Leserinnen und Leser zu ihrer „Kleinen“ und die höchste Reichweite in ihrem Hauptverbreitungsgebiet Steiermark und Kärnten bieten reichweitenstarke und glaubwürdige Umfelder, von
            denen unsere Werbekunden profitieren.
          </p>
        </div>
      </div>
      <div class="row keyword-box justify-content-center">
        <div class="col-lg-6">
          <h4>DIGITALITÄT</h4>
          <p>Nicht nur, dass die Kleine Zeitung bei digitalen Abos österreichweit Marktführer ist, das vielfältige und innovative Angebot an digitalen Werbeformen wie Programmatic Advertising, Display Ads und Sponsored Stories erreicht Ihre Zielgruppe genau dort, wo sie sich grade aufhält – digital, mobile und auf der App.</p>
        </div>
      </div>
      <div class="row keyword-box justify-content-end">
        <div class="col-lg-6">
          <h4>REGIONALITÄT</h4>
          <p>Mit 18 Regionalausgaben in Kärnten und in der Steiermark sind wir fester Teil der Lebenswelt unserer Leser. Adaptieren Sie Ihre Werbebotschaften genau auf die gewünschte geographische Zielgruppe und erreichen Sie Ihre Kunden in ihrem direkten Lebensumfeld.</p>
        </div>
      </div>
      <div class="fact-sheet-btn">
        <a href="../../pdf/allgemeines/KLZ-Fact-Sheet_Tarif_Oktober_2021.pdf" target="_blank"><span class="frontBg">Unsere Kommunikationskraft in Zahlen</span><span class="backBg"></span></a>
      </div>

      <div class="headline-whitespace">
        <h2>Kreative Kommunikation, die Zielgruppen bewegt</h2>
      </div>
      <div class="bild-box-collage margin-40-0">
        <img src="@/assets/img/img-content/startseite/1967-2008-Collage-Tarif-v4.jpg" alt="Startseite Collage" class="img-fluid" />
      </div>
      <div class="headline-whitespace">
        <h2>Gemeinsam mehr erreichen</h2>
      </div>
      <div class="row margin-50-0">
        <div class="col-lg-6 justify-content-center align-self-center">
          <h5>Beratung. Kreation. Umsetzung.</h5>
          <p>An die 10.000 Kunden – regional und national, quer über alle Branchen, vom EPU bis hin zum internationalen Konzern – bauen bereits seit vielen Jahren auf die Kleine Zeitung.</p>
          <p>Unser Angebot für Sie: <strong>Gemeinsam</strong> mit Ihnen entwickeln wir wirksame und kreative Kommunikationslösungen, mit denen Sie Ihre Zielgruppe optimal quer über alle Kanäle des großen Kleine-Zeitung-Netzwerks erreichen.</p>
          <p>Nutzen Sie unser multimediales Produkt-Portfolio für Ihren Werbeerfolg!</p>
        </div>
        <div class="col-lg-6 justify-content-center align-self-center">
          <div class="bild-box border-bg-img">
            <!-- https://www.gettyimages.at/detail/illustration/vector-illustration-of-an-abstract-scheme-lizenfreie-illustration/1131978451-->
            <img src="@/assets/img/img-content/startseite/netzwerk.jpg" alt="Netzwerk" class="img-fluid shadow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'Startseite',
  components: {},
});
</script>
